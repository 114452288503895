import getEnv from "./env"

// Define the enum for all features
export enum Features {
  CONNECTORS = "connectors",
  ASSETS = "assets",
  PREPARE = "prepare",
  DGK = "dgk",
  REST_API_UPLOAD = "restApiUpload",
  UPLOAD_HISTORY = "uploadHistory",
  POLICIES = "policies",
  OUTGOING_DATA_OFFERS = "outgoingDataOffers",
  SUBSCRIBED_DATA_OFFERS = "subscribedDataOffers",
  USE_CASES = "usecases",
  DIGITAL_TWIN_REGISTRY = "digitalTwinRegistry",
  CSV_FILE_UPLOAD = "csvFileUpload",
  JSON_FILE_UPLOAD = "jsonFileUpload",
  CUSTOM_TABLE_ENTRIES = "customTableEntries",
  EDIT_MODELS = "edit-models",
  CROSS_RELATIONS = "crossrelations",
  CONVERTERS = "converters",
  API_ACCESS = "api-access",
  LOGS = "logs",
  PUBLICATION_TEMPLATES = "publication-templates",
  DIGITAL_TWIN_BROWSER = "digitalTwinBrowser",
  DOWNLOAD_OFFERS_HISTORY = "downloadOffersHistory"
}
enum PRODUCT_FLAVOUR_ENUM {
  AUTOMOTIVE = "AUTOMOTIVE",
  NON_AUTOMOTIVE = "NON_AUTOMOTIVE"
}

// use this list to dis/enable features for non-automotive flavour
const NON_AUTOMOTIVE = [
  Features.CONNECTORS,
  Features.ASSETS,
  Features.REST_API_UPLOAD,
  Features.UPLOAD_HISTORY,
  Features.POLICIES,
  Features.OUTGOING_DATA_OFFERS,
  Features.SUBSCRIBED_DATA_OFFERS,
  Features.PUBLICATION_TEMPLATES,
  Features.DOWNLOAD_OFFERS_HISTORY,
  Features.DGK
]

// use this list to dis/enable features for automotive flavour
const AUTOMOTIVE = [
  Features.USE_CASES,
  Features.DIGITAL_TWIN_REGISTRY,
  Features.CONNECTORS,
  Features.CSV_FILE_UPLOAD,
  // Features.JSON_FILE_UPLOAD, // as far as I know, this feature is not enabled at all
  // Features.CUSTOM_TABLE_ENTRIES, // as far as I know, this feature is not enabled at all
  Features.REST_API_UPLOAD,
  Features.UPLOAD_HISTORY,
  Features.PREPARE,
  Features.EDIT_MODELS,
  Features.CROSS_RELATIONS,
  Features.CONVERTERS,
  Features.API_ACCESS,
  Features.POLICIES,
  Features.OUTGOING_DATA_OFFERS,
  Features.SUBSCRIBED_DATA_OFFERS,
  Features.LOGS,
  Features.PREPARE,
  Features.DGK,
  Features.PUBLICATION_TEMPLATES,
  Features.DIGITAL_TWIN_BROWSER,
  Features.DOWNLOAD_OFFERS_HISTORY
]

const currentProductFlavour = getEnv("VITE_PRODUCT_FLAVOUR") as string // Accessing the environment variable

export function featureCheck(features: Features[]) {
  let featureList: Features[] = []

  if (currentProductFlavour === PRODUCT_FLAVOUR_ENUM.NON_AUTOMOTIVE) {
    featureList = NON_AUTOMOTIVE
  } else if (currentProductFlavour === PRODUCT_FLAVOUR_ENUM.AUTOMOTIVE) {
    featureList = AUTOMOTIVE
  }

  // Check if all the features are included in the selected feature list
  return features.every((feature: Features) => featureList.includes(feature))
}

// Define the "standard" feature list for each product flavour
// in future this can be defined by an API call
// These are standard feature per flavor, regardless if user has a certain feature enabled or not
const FeaturePerProductFlavour: {
  [key: string]: Features[]
} = {
  [PRODUCT_FLAVOUR_ENUM.NON_AUTOMOTIVE]: [
    Features.CONNECTORS,
    Features.ASSETS,
    Features.REST_API_UPLOAD,
    Features.UPLOAD_HISTORY,
    Features.POLICIES,
    Features.OUTGOING_DATA_OFFERS,
    Features.SUBSCRIBED_DATA_OFFERS,
    Features.DGK
  ],
  [PRODUCT_FLAVOUR_ENUM.AUTOMOTIVE]: [
    Features.USE_CASES,
    Features.DIGITAL_TWIN_REGISTRY,
    Features.CONNECTORS,
    Features.REST_API_UPLOAD,
    Features.CSV_FILE_UPLOAD,
    Features.JSON_FILE_UPLOAD,
    Features.CUSTOM_TABLE_ENTRIES,
    Features.UPLOAD_HISTORY,
    Features.EDIT_MODELS,
    Features.CROSS_RELATIONS,
    Features.CONVERTERS,
    Features.API_ACCESS,
    Features.POLICIES,
    Features.OUTGOING_DATA_OFFERS,
    Features.SUBSCRIBED_DATA_OFFERS,
    Features.LOGS,
    Features.DGK,
    Features.PREPARE
  ]
}

/**
 * these are the actuall features that are enabled for the user
 * the return of this function will be replaced later by getEnv("VITE_APP_FEATURES") as Features[]
 * @returns {Features[]} list of enabled features
 */
const getEnabledFeatures = () => {
  // for now, I am just returning the defined lists above.
  // Now if we want to disable any feature, we can just comment it out from those lists above
  return currentProductFlavour === PRODUCT_FLAVOUR_ENUM.AUTOMOTIVE
    ? AUTOMOTIVE
    : currentProductFlavour === PRODUCT_FLAVOUR_ENUM.NON_AUTOMOTIVE
      ? NON_AUTOMOTIVE
      : []
}

/**
 * Retrieves the standard features based on the current product flavour.
 * @returns An array of standard features.
 */
const getStandardFeatures = () => {
  return FeaturePerProductFlavour.hasOwnProperty(currentProductFlavour)
    ? FeaturePerProductFlavour[currentProductFlavour]
    : []
}

export const enum FeatureVisibilityOptions {
  DISPLAY = "display",
  DISABLED = "disabled",
  HIDDEN = "hidden"
}

/**
 * Checks if a specific feature is enabled.
 * @param feature - The feature to check.
 * @returns A boolean indicating whether the feature is enabled or not.
 */
const isFeatureEnabled = (feature: Features) => {
  return getEnabledFeatures().includes(feature) && getStandardFeatures().includes(feature)
}

/**
 * Checks if a specific feature is disabled.
 * @param feature - The feature to check.
 * @returns A boolean indicating whether the feature is disabled.
 */
const isFeatureDisabled = (feature: Features) => {
  return !getEnabledFeatures().includes(feature) && getStandardFeatures().includes(feature)
}

/**
 * check if feature should be displayed, diabled or hidden
 * it return a map of given features with their visibility status
 * @param {Features[]} features
 * @returns {[key in Features]: FeatureVisibilityOptions }
 */
export function decideFeaturesVisibility(): {
  [key in Features]: FeatureVisibilityOptions
} {
  // these are the standard features for the current product flavour
  const standardFeatures = getStandardFeatures()
  // creating a map of features with their visibility status
  return standardFeatures.reduce<{
    [key in Features]: FeatureVisibilityOptions
  }>(
    (acc, feature) => {
      if (Object.keys(acc).length === 0) {
        acc = {
          ...acc,
          [feature]: FeatureVisibilityOptions.DISPLAY
        }
      }
      if (isFeatureEnabled(feature)) {
        acc[feature] = FeatureVisibilityOptions.DISPLAY
      } else if (isFeatureDisabled(feature)) {
        acc[feature] = FeatureVisibilityOptions.DISABLED
      } else {
        acc[feature] = FeatureVisibilityOptions.HIDDEN
      }
      return acc
    },
    {} as { [key in Features]: FeatureVisibilityOptions }
  )
}
